import {
  Divider,
  Grid,
  Typography,
  TableBody,
  TableCell,
  TableRow,
  TableHead,
  Table,
  TableContainer,
  Box
} from "@mui/material";
import moment from "moment";
import React from "react";
import { getCurrency, getSubTotal } from "../services/Services";
import "./PrintableInvoice.css";

const PrintableInvoice = React.forwardRef(({ invoice }, ref) => {
  return (
    <div
      className="PrintableInvoice"
      ref={ref}
      style={{
        position: "relative"
      }}
    >
      <style>{`
        .empty-header{
            height:155px 
       }
       .empty-footer{
        height:60px
       }
        .header, .footer {
            position: fixed;
            width: 100%;
       }
        .footer {
            bottom:0; 
            height:60px;
       }
       .header{
            height:125px;
            top: 0;
       }
       table, tr {
        width: 100%;
       }
       .content{
        margin: 50px;
        margin-top:0;
       }
        @media print {
            @page {
                margin: 0 !important;
           }
       }       
      `}</style>
      <table>
        <thead>
          <tr>
            <th>
              <div className="empty-header"></div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <div className="content">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <h2 style={{ margin: "10px 0" }}>Invoice To : </h2>
                    <h3 style={{ margin: "10px 0" }}>{invoice.user.name}</h3>
                    <div>
                      <strong>Phone : </strong> {invoice.user.phone}
                    </div>
                    <div>
                      <strong>Address : </strong> {invoice.user.address}
                    </div>
                  </Grid>
                </Grid>
                <Divider sx={{ margin: "20px 0" }} />
                <TableContainer>
                  <Table
                    sx={{ minWidth: 1000, marginBottom: "20px" }}
                    size={"small"}
                  >
                    <TableHead>
                      <TableRow>
                        <TableCell width={5}>
                          {/* <strong>S/N</strong> */}
                        </TableCell>
                        <TableCell width={450}>
                          <strong>Items</strong>
                        </TableCell>
                        <TableCell align="right" width={100}>
                          <strong>Price</strong>
                        </TableCell>
                        <TableCell align="right" width={50}>
                          <strong>Qty.</strong>
                        </TableCell>
                        <TableCell align="right" width={50}>
                          <strong>GST</strong>
                        </TableCell>
                        <TableCell align="right" width={100}>
                          <strong>Discount</strong>
                        </TableCell>
                        <TableCell align="right" width={100}>
                          <strong>Total</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {invoice.items.map((item, index) => (
                        <TableRow key={item._id}>
                          <TableCell>{index + 1}.</TableCell>
                          <TableCell>{item.name}</TableCell>
                          <TableCell align="right">
                            {getCurrency(item.specifiedPrice)}
                          </TableCell>
                          <TableCell align="right">{item.quantity}</TableCell>
                          <TableCell align="right">{0}</TableCell>
                          <TableCell align="right">{item.discount} %</TableCell>
                          <TableCell align="right">
                            {getCurrency(item.finalPrice)}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>

                <Grid container spacing={2}>
                  <Grid item xs={7}>
                    <h3>Payment Details</h3>
                    <div>
                      <strong>Amount Received : </strong>{" "}
                      {getCurrency(invoice.netPayable)}
                    </div>
                    <div>
                      <strong>Mode : </strong> {invoice.paymentMode}
                    </div>
                    <div>
                      <strong>Reference Details :</strong> {invoice.paymentRef}
                    </div>
                  </Grid>
                  <Grid item xs={5}>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "end"
                      }}
                    >
                      <table style={{ marginTop: "20px" }}>
                        <tbody>
                          <tr>
                            <td width={"150px"}>
                              <h3 style={{ margin: 0 }}>Sub Total : </h3>
                            </td>
                            <td align="right">
                              {getCurrency(getSubTotal(invoice.items))}
                            </td>
                          </tr>
                          <tr>
                            <td width={"150px"}>
                              <h3 style={{ margin: 0 }}>Total CGST:</h3>
                            </td>
                            <td align="right">{getCurrency(0)}</td>
                          </tr>
                          <tr>
                            <td width={"150px"}>
                              <h3 style={{ margin: 0 }}>Total SGST: </h3>
                            </td>
                            <td align="right">{getCurrency(0)}</td>
                          </tr>
                          <tr>
                            <td width={"150px"}>
                              <h3 style={{ margin: 0 }}>Discount : </h3>
                            </td>
                            <td align="right">
                              {getCurrency(invoice.totalDiscount)}
                            </td>
                          </tr>
                          <tr>
                            <td width={"150px"}>
                              <h3 style={{ margin: 0 }}>Grand Total : </h3>
                            </td>
                            <td align="right">
                              <strong>{getCurrency(invoice.netPayable)}</strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
                <h3>Remarks</h3>
                <p>{invoice.remarks}</p>
                <Divider />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    paddingTop: "80px"
                  }}
                >
                  <div
                    style={{
                      width: "30%",
                      display: "flex",
                      justifyContent: "center",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <Divider
                      sx={{
                        width: "100%",
                        margin: "15px",
                        background: "black"
                      }}
                    />
                    <Typography>Authorised Sign</Typography>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              <div className="empty-footer"></div>
            </td>
          </tr>
        </tfoot>
      </table>
      <div className="header">
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            padding: "50px 50px 0 50px",
            boxSizing: "border-box"
          }}
        >
          <div
            style={{
              display: "flex"
            }}
          >
            <div
              style={{
                width: "40px",
                marginRight: "15px"
              }}
            >
              <img
                src="../logo.png"
                alt="logo"
                style={{
                  width: "100%"
                }}
              />
            </div>

            <Box
              sx={{
                fontSize: "30px",
                fontWeight: "bold"
              }}
            >
              AMD Dental Clinic
            </Box>
          </div>

          <div
            style={{
              fontSize: "30px",
              fontWeight: "bold"
            }}
          >
            # TAX INVOICE
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            padding: "0 50px 0 50px",
            boxSizing: "border-box"
          }}
        >
          <div>
            <strong>Address : </strong> A-3, Natraj Nagar near Imli Phatak,
            Jaipur-302015
          </div>
          <div>
            <strong>Invoice ID : </strong> #{invoice.humanId}
          </div>
        </div>
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            padding: "0 50px 20px 50px",
            boxSizing: "border-box"
          }}
        >
          <div>
            <strong>Phone : </strong> +91-9945826926
          </div>
          <div>
            <strong>Invoice Date : </strong>{" "}
            {moment(invoice.billDate).utcOffset("+05:30").format("Do MMM YYYY")}
          </div>
        </div>
        <div style={{ padding: "0 50px" }}>
          <Divider />
        </div>
      </div>
      <div className="footer">
        <div
          style={{
            padding: "0 50px 50px 50px"
          }}
        >
          <Divider />
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
              marginTop: "10px"
              // paddingBottom: "50px"
            }}
          >
            <div>
              <strong>Phone : </strong> +91-9945826926
            </div>
            <div>
              <strong>Address : </strong> A-3, Natraj Nagar near Imli Phatak,
              Jaipur-302015
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PrintableInvoice;
