import axios from "axios";
import { serverUrl } from "../Constants";
import mobile from "is-mobile";
import moment from "moment";

axios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response.status === 401) {
      return Promise.reject({
        tokenExpired: true
      });
    } else if (err.response.status === 404) {
      return Promise.reject({
        notFound: true
      });
    } else if (err.response.status === 500) {
      return Promise.reject({
        serverError: true
      });
    } else {
      return Promise.reject(err);
    }
  }
);

function getToken() {
  let localToken = localStorage.getItem("token");
  return localToken ? `Bearer ${localToken}` : null;
}

export function getUsers(page = 1, pageSize = 10, searchString) {
  return axios({
    method: "get",
    url: `${serverUrl}/customers?page=${page}&pageSize=${pageSize}${
      searchString ? `&search=${searchString}` : ""
    }`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getUsersDetails(id) {
  return axios({
    method: "get",
    url: `${serverUrl}/customers/${id}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getUsersInvoices(id) {
  return axios({
    method: "get",
    url: `${serverUrl}/customers/${id}/invoices`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getInvoiceDetails(id) {
  return axios({
    method: "get",
    url: `${serverUrl}/invoices/${id}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getProducts(searchString) {
  return axios({
    method: "get",
    url: `${serverUrl}/products?page=${1}&pageSize=${10000}${
      searchString ? `&search=${searchString}` : ""
    }`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getinvoices(page = 1, pageSize = 10, searchString, sortBy) {
  let url = `${serverUrl}/invoices?page=${page}&pageSize=${pageSize}`;
  if (searchString) {
    url += `&search=${searchString}`;
  }
  if (sortBy?.key && sortBy?.value) {
    url += `&sortByKey=${sortBy.key}&sortByValue=${sortBy.value}`;
  }
  return axios({
    method: "get",
    url: url,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getNewCustomerAnalysis(value) {
  return axios({
    method: "get",
    url: `${serverUrl}/new-customer-analysis?year=${value}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getIncomeAnalysis(value) {
  return axios({
    method: "get",
    url: `${serverUrl}/income-year-analysis?year=${value}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getInvoiceAnalysis(value) {
  return axios({
    method: "get",
    url: `${serverUrl}/invoice-year-analysis?year=${value}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getBillDetails(from, to) {
  return axios({
    method: "get",
    url: `${serverUrl}/bill-details?from=${from}&to=${to}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function downloadBillDetails(from, to) {
  return axios({
    method: "get",
    url: `${serverUrl}/bill-details/download?from=${from}&to=${to}`,
    headers: {
      Authorization: getToken()
    },
    responseType: "blob" // important
  }).then((response) => {
    const href = URL.createObjectURL(response.data);

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute(
      "download",
      `Bill Details GST - ${moment(from, "YYYY-M-D").format(
        "Do MMM YYYY"
      )} - ${moment(to, "YYYY-M-D")
        .utcOffset("+05:30")
        .format("Do MMM YYYY")}.xlsx`
    );
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return true;
  });
}

export function addProduct(product) {
  return axios({
    method: "post",
    url: `${serverUrl}/products`,
    data: product,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function addPatient(patient) {
  return axios({
    method: "post",
    url: `${serverUrl}/customers`,
    data: patient,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function createInvoice(invoice) {
  return axios({
    method: "post",
    url: `${serverUrl}/invoices`,
    data: invoice,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function updateInvoice(id, invoice) {
  return axios({
    method: "put",
    url: `${serverUrl}/invoices/${id}`,
    data: invoice,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function updateProduct(id, product) {
  return axios({
    method: "put",
    url: `${serverUrl}/products/${id}`,
    data: product,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function updatePatient(id, patient) {
  return axios({
    method: "put",
    url: `${serverUrl}/customers/${id}`,
    data: patient,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function getActivityLogs(id) {
  return axios({
    method: "get",
    url: `${serverUrl}/activity-logs/${id}`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function sendInvoiceEmail(id) {
  return axios({
    method: "post",
    url: `${serverUrl}/invoices/${id}/email`,
    headers: {
      Authorization: getToken()
    }
  }).then(function (response) {
    return response.data;
  });
}

export function logInvoiceAction(id, action) {
  return axios({
    method: "post",
    url: `${serverUrl}/invoices/${id}/action`,
    headers: {
      Authorization: getToken()
    },
    data: {
      action
    }
  }).then(function (response) {
    return response.data;
  });
}

export function printInvoice(id, name) {
  return axios({
    url: `${serverUrl}/invoices/${id}/download`, //your url
    method: "GET",
    headers: {
      Authorization: getToken()
    },
    responseType: "blob" // important
  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${name}.pdf`); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    return true;
  });
}

export function getDiscount(unitPrice, quantity, finalPrice) {
  if (unitPrice === 0) {
    return 0;
  }
  let discount =
    ((unitPrice * quantity - finalPrice) / (unitPrice * quantity)) * 100;
  return Number.isInteger(discount)
    ? Math.trunc(discount)
    : parseFloat(discount.toFixed(2));
}

export function getTotalAmount(items) {
  let total = items?.length
    ? items.reduce(
        (a, i) =>
          a + (isNaN(parseFloat(i.finalPrice)) ? 0 : parseFloat(i.finalPrice)),
        0
      )
    : 0;
  return Number.isInteger(total)
    ? Math.trunc(total)
    : parseFloat(total.toFixed(2));
}

export function getFinalPrice(unitPrice, quantity, discount) {
  let price = unitPrice * quantity - (discount / 100) * unitPrice * quantity;
  return Number.isInteger(price)
    ? Math.trunc(price)
    : parseFloat(price.toFixed(2));
}

export function getTotalDiscount(items) {
  let totalDiscount = 0;
  items.forEach((item) => {
    totalDiscount =
      totalDiscount +
      (parseFloat(item.quantity) * parseFloat(item.specifiedPrice) -
        parseFloat(item.finalPrice));
  });
  return Number.isInteger(totalDiscount)
    ? Math.trunc(totalDiscount)
    : parseFloat(totalDiscount.toFixed(2));
}

export function getInvoiceTotal(items) {
  let total = 0;
  items.forEach((item) => {
    total = total + parseFloat(item.finalPrice);
  });
  return Number.isInteger(total)
    ? Math.trunc(total)
    : parseFloat(total.toFixed(2));
}

export function getCurrency(num) {
  if (typeof num === "number") {
    return num.toLocaleString("en-IN");
  }
  if (typeof num === "string") {
    let n = parseFloat(num);
    let _n = Number.isInteger(n) ? Math.trunc(n) : parseFloat(n.toFixed(2));
    return _n.toLocaleString("en-IN");
  }
}

export function getSubTotal(items) {
  let total = 0;
  items.forEach((item) => {
    total = total + parseFloat(item.specifiedPrice) * item.quantity;
  });
  return Number.isInteger(total)
    ? Math.trunc(total)
    : parseFloat(total.toFixed(2));
}

export function isMobile() {
  return mobile();
}

export function equalDate(d1, d2) {
  return new Date(d1).getTime() === new Date(d2).getTime();
}

export function isDate(str) {
  return moment(str, moment.ISO_8601, true).isValid();
}

export function getObjectDiff(old, latest) {
  const result = {};
  for (let key in latest) {
    if (old[key] !== undefined) {
      if (isDate(old[key])) {
        if (!equalDate(latest[key], old[key])) {
          result[key] = latest[key];
        }
      } else if (latest[key] !== old[key]) {
        result[key] = latest[key];
      }
    } else {
      result[key] = latest[key];
    }
  }
  return Object.keys(result).length ? result : null;
}

export function getArrayDiff(old, latest, key) {
  let added = [];
  let removed = [];
  let updated = [];
  let oldKeys = old.reduce((acc, i) => {
    acc[i[key]] = i;
    return acc;
  }, {});

  let latestKeys = latest.reduce((acc, i) => {
    acc[i[key]] = i;
    return acc;
  }, {});

  old.forEach((i) => {
    if (latestKeys[i[key]]) {
      let diff = getObjectDiff(i, latestKeys[i[key]]);
      if (diff) {
        updated.push(latestKeys[i[key]]);
      }
    } else {
      removed.push(i);
    }
  });

  latest.forEach((i) => {
    if (!oldKeys[i[key]]) {
      added.push(i);
    }
  });

  return { added, removed, updated };
}

export function safeParse(num) {
  let _n = parseFloat(num);
  return Number.isInteger(_n) ? Math.trunc(_n) : parseFloat(_n);
}

export function isValidEmail(email) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}
