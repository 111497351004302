import AMDMainWindow from "./AMDMainWindow/AMDMainWindow";
import { QueryClient, QueryClientProvider } from "react-query";
import UserContext from "./Common/UserContext";
import { BrowserRouter as Router } from "react-router-dom";
import NotificationContext from "./Common/NotificationContext";
import "./App.css";
const queryClient = new QueryClient();
function MyApp() {
  return (
    <NotificationContext>
      <UserContext>
        <QueryClientProvider client={queryClient}>
          <Router>
            <AMDMainWindow />
          </Router>
        </QueryClientProvider>
      </UserContext>
    </NotificationContext>
  );
}

export default MyApp;
