import { useState } from "react";
import { Snackbar } from "@mui/material";

function useSnackbar() {
  const [open, setOpen] = useState();
  const [message, setMessage] = useState();

  const snackbarElement = (
    <Snackbar
      open={open}
      autoHideDuration={4000}
      message={message}
      onClose={() => setOpen(false)}
    />
  );

  const notify = (message) => {
    setMessage(message);
    setOpen(true);
  };

  return {
    notify,
    snackbarElement
  };
}

export default useSnackbar;
