// export const primaryColor = "rgb(105, 65, 198)";
export const primaryColor = "#708d92";
export const secondaryColor = "#1e88e5";
export const secondaryColorLight = "#FBDCC5";
export const secondaryGray = "#9f9f9f";
export const semiTransparentBG = "rgba(255, 255, 255, 0.1)";
export const semiTransparentBGBlack = "rgba(0, 0, 0, 0.2)";
export const drawerBG = "rgb(249,250,251)";
export const blackColor = "#344054";
// export const serverUrl = "http://localhost:3001";
export const serverUrl = "https://api.amddentalclinic.com";
