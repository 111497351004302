import PrintableInvoice from "../InvoiceDetails/PrintableInvoice";
import useFetch from "../Common/useFetch";
import { serverUrl } from "../Constants";
import { useEffect, useState } from "react";
import axios from "axios";
export default function PrintData({ data, token }) {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const verify = async () => {
      try {
        const data = await axios
          .post(`${serverUrl}/verify-token`, {
            token
          })
          .then((response) => response.data);
        if (data?.valid) {
          setShow(true);
        }
      } catch (e) {
        return null;
      }
    };
    verify();
  }, [token]);

  if (!show) {
    return <h1>Loading...</h1>;
  }

  let invoice;
  try {
    invoice = JSON.parse(data);
    if (isInValidInvoice(invoice)) {
      throw new Error("INVALID_INVOICE");
    }
    return (
      <div id="printData">
        <PrintableInvoice invoice={invoice} color={true} />
      </div>
    );
  } catch (e) {
    return <h1 id="printData">Some Error occured, Please try later</h1>;
  }
}

function isInValidInvoice(invoice) {
  if (!invoice.humanId) {
    return true;
  }
  if (!invoice.billDate) {
    return true;
  }
  if (!invoice.createdAt) {
    return true;
  }

  if (!invoice.updatedAt) {
    return true;
  }

  if (!invoice.user) {
    return true;
  }

  if (!invoice._id) {
    return true;
  }
}
