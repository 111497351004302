import axios from "axios";
import { useCallback, lazy, Suspense } from "react";
import { useEffect, useRef, useContext, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { Context } from "../Common/UserContext";
import { NotifyContext } from "../Common/NotificationContext";
import useSearchQuery from "../Common/useSearchQuery";
import { serverUrl } from "../Constants";
import PageLoader from "../Common/PageLoader";
import PrintData from "../PrintData/PrintData";

const Products = lazy(() => import("../Products/Products"));
const EditInvoice = lazy(() => import("../EditInvoice/EditInvoice"));
const YearAnalysis = lazy(() => import("../YearAnalysis/YearAnalysis"));
const BillDetails = lazy(() => import("../BillDetails/BillDetails"));
const ActivityLog = lazy(() => import("../ActivityLog/ActivityLog"));
const PatientDetails = lazy(() => import("../PatientDetails/PatientDetails"));
const AMDAppBar = lazy(() => import("../AMDAppBar/AMDAppBar"));
const Login = lazy(() => import("../Login/Login"));
const InvoiceDetails = lazy(() => import("../InvoiceDetails/InvoiceDetails"));
const Patients = lazy(() => import("../Patients/Patients"));
const Invoices = lazy(() => import("../Invoices/Invoices"));
const NewInvoice = lazy(() => import("../NewInvoice/NewInvoice"));

function AMDMainWindow() {
  const userContext = useContext(Context);
  const notificationManager = useContext(NotifyContext);
  const query = useSearchQuery();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const dataFetchedRef = useRef(false);

  const proceedFurther = useCallback(() => {
    const lastLocation = localStorage.getItem("lastLocation");
    if (lastLocation) {
      localStorage.removeItem("lastLocation");
      navigate(lastLocation);
    }
  }, [navigate]);

  const verifyUser = useCallback(
    async (token) => {
      try {
        const data = await axios
          .post(`${serverUrl}/verify-token`, {
            token
          })
          .then((response) => response.data);
        return data;
      } catch (e) {
        if (e?.response?.data?.message) {
          notificationManager.notify(e?.response?.data?.message);
        }
        return null;
      }
    },
    [notificationManager]
  );

  const validate = useCallback(async () => {
    setLoading(true);
    let existingToken = localStorage.getItem("token");
    const newToken = query.get("token");
    if (newToken) {
      localStorage.removeItem("token");
      let result = await verifyUser(newToken);
      if (result?.valid) {
        localStorage.setItem("token", newToken);
        userContext.setCurrUser(result.user);
        proceedFurther();
      } else {
        notificationManager.notify("Unauthorized User.");
      }
    } else if (existingToken) {
      let result = await verifyUser(existingToken);
      if (result?.valid) {
        userContext.setCurrUser(result.user);
        proceedFurther();
      } else {
        notificationManager.notify("Unauthorized User.");
      }
    }
    setLoading(false);
  }, [notificationManager, proceedFurther, verifyUser, query, userContext]);

  useEffect(() => {
    if (!dataFetchedRef.current) {
      if (
        !query.get("printData") &&
        !query.get("tempToken") &&
        !query.get("unauthorizedLogin")
      ) {
        console.log("Verifying Token......");
        validate();
      }
      dataFetchedRef.current = true;
    }
  }, [validate, query]);

  if (query.get("printData") && query.get("tempToken")) {
    document.body.style.backgroundColor = "white";
    return (
      <PrintData data={query.get("printData")} token={query.get("tempToken")} />
    );
  }

  if (loading) {
    return <PageLoader />;
  }

  if (!userContext.currUser || query.get("unauthorizedLogin")) {
    if (query.get("unauthorizedLogin")) {
      notificationManager.notify("Unauthorized User.");
    }
    return (
      <Suspense fallback={<PageLoader />}>
        <Login />
      </Suspense>
    );
  }

  return (
    <Box sx={{ display: "flex" }}>
      {<AMDAppBar />}

      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route exact path="/" element={<Navigate to={"/patients"} />} />
          <Route path="/patients" element={getMainView(<Patients />)} />
          <Route
            path="/patients/:id"
            element={getMainView(<PatientDetails />)}
          />
          <Route path="/invoices" element={getMainView(<Invoices />)} />
          <Route
            path="/edit-invoice/:id"
            element={getMainView(<EditInvoice />)}
          />
          <Route
            path="/invoices/:id"
            element={getMainView(<InvoiceDetails />)}
          />
          <Route path="/manage-products" element={getMainView(<Products />)} />
          <Route path="/new-invoice" element={getMainView(<NewInvoice />)} />
          <Route
            path="/year-analysis"
            element={getMainView(<YearAnalysis />)}
          />
          <Route path="/bill-details" element={getMainView(<BillDetails />)} />
          <Route exact path="/login" element={<Login />} />
          <Route
            exact
            path="/activity-logs/:id"
            element={getMainView(<ActivityLog />)}
          />
          <Route path="*" element={<Navigate to={"/"} />} />
        </Routes>
      </Suspense>
    </Box>
  );
}

function getMainView(component) {
  return (
    <Box
      sx={{
        pt: "90px",
        width: "100%"
      }}
    >
      <div style={{ width: "100%" }}>{component}</div>
    </Box>
  );
}

export default AMDMainWindow;
