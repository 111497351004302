import useSnackbar from "./useSnackbar";
import { createContext } from "react";

export const NotifyContext = createContext();

function NotificationContext({ children }) {
  const { notify, snackbarElement } = useSnackbar();
  return (
    <NotifyContext.Provider value={{ notify }}>
      {children}
      {snackbarElement}
    </NotifyContext.Provider>
  );
}

export default NotificationContext;
